import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { AuthGuard } from './guards/auth.guard';
import { OktaLoginComponent } from './authentication/okta/okta-login/okta-login.component';
import { OktaCallbackComponent } from './authentication/okta/okta-callback/okta-callback.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import {TestpageComponent} from "./testpage/testpage.component";

export const appRoutes: Routes = [

  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {path: 'testpage',
  component: TestpageComponent,
  canActivate: [AuthGuard]
  },
  { path: 'okta/login', component: OktaLoginComponent, data: { onSigning: true } },
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent, data: { footerVisible: true } },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(
  appRoutes,
  {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  }
);
