import { DOCUMENT, PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { OktaAuth } from '@okta/okta-auth-js';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import * as fromStore from '../../store';
import { BaseAuthenticationProvider } from './base-authentication.provider';


@Injectable()
export class OktaAuthenticationProvider implements BaseAuthenticationProvider, OnDestroy {

  public oktaAuth: OktaAuth;
  private isAccessTokenRenewing = false;
  private isIdTokenRenewing = false;

  private oktaConfig = {
    clientId: environment.oktaClientId,
    issuer: environment.oktaIssuer,
    redirectUri: OktaAuthenticationProvider.getOktaRedirectURI(),
    maxClockSkew: (60 * 60 * 3),
    pkce: true,
    tokenManager: {
      autoRenew: false,
      expireEarlySeconds: 120
    },
  };

  private actionsSubscription = null;
  private userSubscription = null;
  private accessToken: any = null;

  protected isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public userSubject = new Subject<any>();
  public userInfo;
  userName: string;
  emailId: string;
  oktaId: any;
  email: any;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public loc: PlatformLocation,
    @Inject(DOCUMENT) private doc: any,
    private injector: Injector,
    private oktaStore: Store<fromStore.OktaState>,
    private actionsSubject: ActionsSubject,
  ) {
    this.oktaAuth = new OktaAuth(this.oktaConfig);
  }

  public static getOktaRedirectURI(): string {
    // const port = window.location.port ? ':' + window.location.port : '';
    // return window.location.protocol + '//' + window.location.hostname + port + environment.oktaRedirectPath;
    return environment.oktaRedirectPath;
  }

  private setupAuthHandlers() {

    // Triggered when a token has expired
    this.oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
      // Renew tokens after expiring
      this.renewToken(key, expiredToken);
    });

    // Triggered when an OAuthError is returned via the API (typically during token renew)
    this.oktaAuth.tokenManager.on('error', (err) => {
      // err.name
      // err.message
      // err.errorCode
      // err.errorSummary
      // err.tokenKey
      // err.accessToken
      console.error('Token Manager Error:', err.message);
    });
  }

  public renewToken(key, expiredToken) {

    // Prevent multiple renew requests for id token at the same time
    if (key === 'idToken') {
      if (this.isIdTokenRenewing === true) {
        return;
      } else {
        this.isIdTokenRenewing = true;
      }
    }

    // Prevent multiple renew requests for id token at the same time
    if (key === 'accessToken') {
      if (this.isAccessTokenRenewing === true) {
        return;
      } else {
        this.isAccessTokenRenewing = true;
      }
    }

    this.oktaAuth.token.renew(expiredToken)
      .then(freshToken => {
        // manage freshToken
        switch (key) {
          case 'idToken':
            this.oktaStore.dispatch(new fromStore.UpdateIdToken(freshToken));
            this.oktaAuth.tokenManager.add('idToken', freshToken);
            this.isIdTokenRenewing = false;
            break;
          case 'accessToken':
            this.accessToken = freshToken;
            this.oktaStore.dispatch(new fromStore.UpdateAccessToken(freshToken));
            this.oktaAuth.tokenManager.add('accessToken', freshToken);
            this.isAccessTokenRenewing = false;
            break;
        }
      })
      .catch(err => {

        this.isIdTokenRenewing = false;
        this.isAccessTokenRenewing = false;

        if (environment.enableConsoleLog) {
          console.log(err);
        }

        // handle OAuthError
        this.handle401Response();
      });
  }

  private sessionExpiredHandler(): void {
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    const ngZone = <NgZone>this.injector.get(NgZone);
    ngZone.run(() => {
      this.router.navigate(['/session-expired']);
    });
  }

  public isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.getValue();
  }

  public isAuthenticatedObs(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  public handle401Response(): void {
    this.logout();
    this.redirectToLogin();
  }

  public redirectToLogin() {
    this.router.navigate(['/okta/login']);
  }

  public login() {
    let brandedPageIDP;

    if (environment.brandedLoginPagesIDPs) {
      Object.keys(environment.brandedLoginPagesIDPs).forEach(function (domain) {
        if (window.location.href && window.location.href.includes(domain)) {
          brandedPageIDP = environment.brandedLoginPagesIDPs[domain];
        }
      });
    }
    this.oktaAuth.token.getWithRedirect({
      responseType: ['id_token', 'token'],
      scopes: ['openid', 'email', 'profile'],
      idp: brandedPageIDP,
      clientId: environment.oktaClientId,
    });
  }

  public async logout() {
    this.isAuthenticatedSubject.next(false);
    this.oktaAuth.tokenManager.clear();
    // await this.oktaAuth.signOut();
  }

  public getHeaderOptions(): any {
    const options = {
      Authorization: ''
    };

    if (this.accessToken) {
      options.Authorization = `Bearer ${this.accessToken.accessToken}`;
    }
    return options;
  }

  public getToken(): any {
    return this.accessToken;
  }

  public async loadToken() {
    return this.oktaAuth.tokenManager.get('accessToken');
    // return false;
  }

  public getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  public async handleAuthentication() {
    let tokens;

    this.accessToken = await this.oktaAuth.tokenManager.get('accessToken');
    if (!this.accessToken) {

      try {
        tokens = await this.oktaAuth.token.parseFromUrl();
        this.resetLoginFailureCount();
      } catch (e) {
        console.error(e.message);
        if (e.message.indexOf('not assigned') > -1) {   // User is not assigned to the client application
          return Promise.reject(new Error('Not assigned the ' + environment.applicationName + ' application in Okta'));
        }
        this.incrementLoginFailureCount();
        return Promise.reject(Error('Invalid Token'));
      }

      if (tokens?.tokens?.idToken) {
        this.oktaStore.dispatch(new fromStore.UpdateIdToken(tokens.tokens.idToken));
        this.oktaAuth.tokenManager.add('idToken', tokens.tokens.idToken);
      }
      if (tokens?.tokens?.accessToken) {
        this.accessToken = tokens.tokens.accessToken;
        this.oktaStore.dispatch(new fromStore.UpdateAccessToken(tokens.tokens.accessToken));
        this.oktaAuth.tokenManager.add('accessToken', tokens.tokens.accessToken);
      }
    } else {
      const idToken = await this.oktaAuth.tokenManager.get('idToken');
      this.oktaStore.dispatch(new fromStore.UpdateIdToken(idToken));
      this.oktaStore.dispatch(new fromStore.UpdateAccessToken(this.accessToken));
    }
    await this.authenticateWithOktaToken();
  }


  private async authenticateWithOktaToken() {
    const headerOptions = this.getHeaderOptions();

    this.userInfo = await this.oktaAuth.token.getUserInfo();
    this.userName = this.userInfo.name;
    this.oktaId = this.userInfo.sub;
    this.email = this.userInfo.email;
    this.userSubject.next(this.userInfo);
    this.isAuthenticatedSubject.next(true);
  }

  getUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  resetLoginFailureCount() {
    localStorage.setItem('oktaLoginErrorCount', '0');
  }

  incrementLoginFailureCount(): number {
    const retryCount = this.getLoginFailureCount() + 1;
    localStorage.setItem('oktaLoginErrorCount', String(retryCount));

    return retryCount;
  }

  getLoginFailureCount(): number {
    const retryCount = localStorage.getItem('oktaLoginErrorCount');
    return (_.isNaN(retryCount) || _.isNull(retryCount)) ? 0 : +retryCount;
  }

  async getAppTokens(): Promise<{ accessToken: string, idToken: string }> {
    const accessToken = await this.oktaAuth.tokenManager.get('accessToken');
    const idToken = await this.oktaAuth.tokenManager.get('idToken');

    return {
      accessToken: accessToken?.accessToken,
      idToken: idToken?.idToken
    };
  }

  ngOnDestroy() {
    this.actionsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }
}
