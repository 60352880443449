<mat-grid-list cols="3" rowHeight="3:1">
  <mat-grid-tile [colspan]="2"[style.background]="'lightgreen'">Box 1 </mat-grid-tile>
  <mat-grid-tile [colspan]="1" [style.background]="'lightblue'">
    <div class="user-card">
      <mat-card>
        <mat-card-title><h2> Notifications</h2></mat-card-title>
        <mat-card-content>
          <ul>
            <li>Item 1</li>
            <li>Item 2</li>
            <li>Item 3</li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile [colspan]="3" [style.background]="'lightpink'">Box 3
    <div style="height: 700px;width:100px;background: #000"></div></mat-grid-tile>
</mat-grid-list>
