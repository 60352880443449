import { Injectable } from '@angular/core';
import { OktaAuthenticationProvider } from './auth-providers/okta-authentication.provider';
import { environment } from '../../environments/environment';
import { BaseAuthenticationProvider } from './auth-providers/base-authentication.provider';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthenticationService {
  isPostLogin = new BehaviorSubject(false);

  private activeAuthService: BaseAuthenticationProvider;

  private currentPath: UrlSegment[];
  private currentQueryParams: {};

  constructor(
    private oktaAuthService: OktaAuthenticationProvider,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private httpClient: HttpClient,

  ) {
    if (environment.oktaAuthEnabled) {
      this.activeAuthService = this.oktaAuthService;
    } else {
    }

    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });
  }

  redirectToLogin(): void {
    this.activeAuthService.redirectToLogin();
  }

  redirectPostLogin(): void {
    const postLoginRedirectTo: string = localStorage.getItem('post-login-redirect');
    this.isPostLogin.next(true);
    if (postLoginRedirectTo && postLoginRedirectTo.length > 0) {
      this.router.navigateByUrl(postLoginRedirectTo);
    } else {
      this.router.navigate(['/dashboard']);
    }
    localStorage.removeItem('post-login-redirect');
  }

  getPostLogin(): Observable<boolean> {
    return this.isPostLogin.asObservable();
  }

  redirectToLogout(): void {
    setTimeout(() => {
      this.router.navigate(['logout']);
    }, 500);
  }

  logout(): void {
    sessionStorage.clear();
    this.activeAuthService.logout();
  }

  public getToken(): any {
    return this.activeAuthService.getToken();
  }

  public handle401Response(error): void {
    // Check if current user API has a 401 error
    if (error?.url?.indexOf('current-user') > -1 ) {
      this.activeAuthService.logout();
      // Clear the address bar URL
      this.location.replaceState('');
      // Navigate to custom component to display the unauthorised message
      this.router.navigate(['/incorrect-credentials'], { skipLocationChange: true });
      // Check if user is impersonating when a 401 error is recieved
     // this.storeHelper.checkIfImpersonatingOn401(error);
    } else {
      this.activeAuthService.handle401Response();
    }
  }

  public getHeaderOptions(): any {
    return this.activeAuthService.getHeaderOptions();
  }

  public isAuthenticated(): boolean {
    return this.activeAuthService.isAuthenticated();
  }

  public isAuthenticatedObs(): Observable<boolean> {
    return this.activeAuthService.isAuthenticatedObs();
  }
}
