// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseURL: '',
  unsupportedBroowserUrl: '',
  snackbarTimeOut: 2000,
  oktaAuthEnabled: true,
  oktaTenantUrl: 'https://realogy.oktapreview.com',
  oktaIssuer: 'https://realogy.oktapreview.com/oauth2/ausfby7lsqSlV4VZY0h7',
  oktaClientId: '0oa158eh2mwMOfZxW0h8',
  oktaMaxClockSkew: (60 * 60 * 6), // 6 hours
  oktaRedirectPath: '/implicit/callback',
  brandedLoginPagesIDPs: {
    homeplace: '',
  },
  applicationName: 'Home Place',
  enableConsoleLog: true,
  mobileWidthMax: 470,
  tabletWidthMax: 1024,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
