import { Component, OnInit } from '@angular/core';
import { OktaAuthenticationProvider } from 'src/app/sevices/auth-providers/okta-authentication.provider';
import { AuthenticationService } from 'src/app/sevices/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-okta-callback',
  templateUrl: './okta-callback.component.html',
  styleUrls: ['./okta-callback.component.scss']
})
export class OktaCallbackComponent implements OnInit {
  public errorMsg: any;
  public hasError = false;
  public gatewayReturnLink: string;

  constructor(
    private auth: AuthenticationService,
    private okta: OktaAuthenticationProvider
  ) { }

  ngOnInit() {
    // Handles the response from Okta and parses tokens
   // this.gatewayReturnLink = environment.gatewayURL + '?desk=return';

    this.okta.handleAuthentication().then(() => {
      this.auth.redirectPostLogin();
    }).catch(error => {
      this.errorMsg = error.message;
      this.hasError = true;
      return;
    });

  }

  retry() {
    this.okta.logout();
    this.okta.login();
  }
}
