<div class="container text-center">
  <div class="wrapper" *ngIf="!hasError">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <br><br>
    <h1 class="lead">Signing in ....</h1>
  </div>
  <div class="wrapper retry" *ngIf="hasError">
    <div class="alert" role="alert">
      Sign in failed: <span [innerHtml]="errorMsg"></span>
    </div>
    <div class="contact-message">If this problem persists, please contact the Help Desk: 877.NRT.HELP
      (877.678.4357)
    </div>
    <br>
    <button type="button" mat-raised-button color="primary" (click)="retry()">Try again</button>
  </div>
</div>