import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { AppLoadModule } from './app-load.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { routing } from './app.routing';
import { MaterialModule } from './modules/material/material.module';
import { CreateEventModalComponent } from './shared/modals/create-event-modal/create-event-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnackbarComponent } from './modules/material/snackbar/snackbar.component';
import { SnackbarService } from './sevices/snackbar.service';
import { OktaLoginComponent } from './authentication/okta/okta-login/okta-login.component';
import { OktaCallbackComponent } from './authentication/okta/okta-callback/okta-callback.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticationService } from './sevices/authentication.service';
import { OktaAuthenticationProvider } from './sevices/auth-providers/okta-authentication.provider';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { oktaReducers } from './store/reducers/okta.reducers';
import { effects } from './store/effects';
import { ApplicationService } from './sevices/application.service';
import { RscClientService } from './sevices/rsc-client.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { reducers } from './store';
import { LogoutComponent } from './logout/logout.component';
import {TestpageComponent} from "./testpage/testpage.component";
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component';
import { MessagesComponent } from './messages/messages.component';
@NgModule({
  declarations: [
    AppComponent,
    SnackbarComponent,
    LoginComponent,
    LogoutComponent,
    CreateEventModalComponent,
    OktaCallbackComponent,
    OktaLoginComponent,
    DashboardComponent,
    TestpageComponent,
    TransactionDetailComponent,
    MessagesComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    routing,
    BrowserModule,
    AppLoadModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
  ],
  providers: [
    SnackbarService,
    AuthGuard,
    AuthenticationService,
    ApplicationService,
    RscClientService,
    OktaAuthenticationProvider,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
