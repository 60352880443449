import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthenticationProvider } from 'src/app/sevices/auth-providers/okta-authentication.provider';

@Component({
  selector: 'app-okta-login',
  templateUrl: './okta-login.component.html',
  styleUrls: ['./okta-login.component.scss']
})
export class OktaLoginComponent implements OnInit {

  constructor(
    private oktaAuthService: OktaAuthenticationProvider,
    private router: Router
  ) { }

  async ngOnInit() {
    const token = await this.oktaAuthService.loadToken();
    if (token) {
      this.router.navigate(['implicit', 'callback']);
    } else {
      this.oktaAuthService.login();
    }
  }
}
