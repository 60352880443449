import { AfterViewChecked, AfterViewInit, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApplicationService } from './sevices/application.service';
import { OktaAuthenticationProvider } from './sevices/auth-providers/okta-authentication.provider';
import { RscClientService } from './sevices/rsc-client.service';
import { SidenavState } from './shared/models/sidenav-state.model';
import { OktaCallbackComponent } from './authentication/okta/okta-callback/okta-callback.component';
import { AuthenticationService } from './sevices/authentication.service';

function _window(): any {
  return window;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'homeplace';
  router$: Subscription;

  private rscClient: any;
  public mounted = false;
  public isSetMenu = false;
  user;

  mobile: boolean;
  tablet: boolean;
  isAnonymous: any;
  pagePath: string;

  constructor(
    private ngZone: NgZone,
    private authService: OktaAuthenticationProvider,
    private authenticationService: AuthenticationService,
    private route: Router,
    private appService: ApplicationService,
    private rscClientService: RscClientService
    ) {
  }

  ngOnInit() {
    this.ngZone.run(() => this.mounted = true);
    this.authService.isAuthenticatedObs().pipe(
      map(authenticated => {
        const token = this.authService.getToken();
        if (token) {
          this.rscClient.setAccessToken(token.accessToken);
        }
        return authenticated;
      })
    ).subscribe(userData => {
      if (userData) { console.log(userData); }
    });

    this.router$ = this.route.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd || event instanceof RoutesRecognized)
      )
      .subscribe(event => {
        if (event instanceof RoutesRecognized) {
          const routeData = event.state.root.firstChild.data;

          if (routeData) {

            if (this.isAnonymous !== routeData.anonymous) {
              this.isAnonymous = routeData.anonymous;
              this.setSidenavState();
            }
          }
        }
        if (event instanceof NavigationEnd) {
          this.pagePath = event.url;
          if (this.appService.getIsTouchDevice()) {
            this.rscClientService.toggleSideNav(false);
          }
          this.rscClientService.setCurrentUrl(event.url);
        }
      });
  }

  ngAfterViewInit() {
   this.rscClient =  _window().__RSC__;
   this.authService.getUser().subscribe(user => {
      if (user) {
        if (this.rscClient && !this.isSetMenu && this.authService.userName) {
          const accessToken = this.authService.getToken();
          if (accessToken) { this.rscClient.setAccessToken(accessToken.accessToken); }
          this.rscClient.setUsername(this.authService.userName);
          this.rscClient.setOktaId(this.authService.oktaId);
          this.rscClient.showUserNameInTopnav(true);
          this.isSetMenu = true;
          this.rscClient.setMenuItem({
            name: 'My Profile', icon: 'far fa-user', callback: () => {
            }
          });
          this.rscClient.setMenuItem({
            name: 'Settings', icon: 'fal fa-cog', callback: () => {
            }
          });

          this.rscClient.setMenuItem({
            name: 'Sign Out', icon: 'fal fa-sign-out-alt', callback: () => {
              this.authenticationService.redirectToLogout();
            }
          });

          this.rscClient.setSpeedDialOption({
            name: 'Leave Feedback',
            icon: 'fal fa-comment-exclamation',
            weight: 2,
            url: 'https://www.mycbdesk.com/feedback',
            target: '_blank',
          });

          // with callback
          this.rscClient.setSpeedDialOption({
            name: 'See Help Articles & FAQs',
            icon: 'fal fa-question-circle',
            callback: function(option) {window.open('https://www.mycbdesk.com/help-center', '_blank')},
          });

          this.rscClient.setSidenav([
            {
              // First level
              icon: 'fal fa-home',
              label: 'Home',
              url: 'dashboard',
              callback: (() => this.route.navigate(['dashboard'])).bind(this),
              childItems: []
            },
            {
              // First level
              icon: 'fal fa-bells',
              label: 'Events',
              url: 'events/events-list',
              callback: (() => this.route.navigate(['events/events-list'])).bind(this),
              childItems: [
                {
                  label: 'List of Events',
                  url: 'events/events-list',
                  callback: (() => this.route.navigate(['events/events-list'])).bind(this),
                  childItems: [
                  ]
                },
                {
                  label: 'Events Watch',
                  url: 'events/events-watch',
                  callback: (() => this.route.navigate(['events/events-watch'])).bind(this),
                  childItems: [
                  ]
                }
              ]
            }
          ]);
        }
      }
    });
   this.setSidenavState();
  }

  redirectToLogout() {
    this.ngZone.run(() => {
      this.route.navigate(['okta/logout']);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onDeviceChange(event);
    this.setSidenavState();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationchange(event) {
    this.onDeviceChange(event);
  }

  onDeviceChange(event?) {
    const widthToSend = event ? event.target.innerWidth : window.innerWidth;
    this.appService.setScreenWidth(widthToSend);
    this.mobile = this.appService.isPhoneDevice();
  }

  setSidenavState() {
    const navState = new SidenavState();
    if (this.authService.isAuthenticated()) {
      if (this.mobile || (this.tablet && window.innerWidth < 992)) {
        navState.deserialize(
          {
            enableCache: false,
            enableStaggeredNav: false,
            state: 'closed',
            hasBackdrop: true,
          }
        );
      } else {
        navState.deserialize(
          {
            enableCache: true,
            enableStaggeredNav: true,
            state: 'open',
            hasBackdrop: false,
          }
        );
      }
    }
    this.rscClientService.setSidenavState(navState);
  }
}
