import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../sevices/authentication.service';
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  isAuthenticated: boolean;

  constructor(private authService: AuthenticationService) { }

  checkAuth(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      // logged in so return true
      this.isAuthenticated = true;
    } else {
      // not logged in so redirect to login page
      const postLoginRedirectTo = state.url;
      localStorage.removeItem('post-login-redirect');
      if (postLoginRedirectTo) {
        localStorage.setItem('post-login-redirect', state.url);
      }
      this.authService.redirectToLogin();
      this.isAuthenticated = false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.checkAuth(route, state);
    return this.isAuthenticated;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.checkAuth(route, state);
    return this.isAuthenticated;
  }
}
